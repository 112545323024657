// Footer

/* Footer Top Start */
.footer {
	background-color: #0b0d0f;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;

	h6 {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		letter-spacing: 5px;
		font-size: 12px;
		line-height: 12px;
		color: #999999;
		text-transform: uppercase;
		margin: 0 0 25px;
	}

	.btn-default {
		border: 2px solid #cc9933;
		background: none;
		color: #cc9933;
		letter-spacing: 5px;

		&:hover {
			background-color: #cc9933;
			color: #fff;
		}
	}

	/* Footer Top Start */
	.f-top {
		padding: 0 0 50px;
		.row {
			margin: 0 -35px;

			[class*=col-] {
				padding: 0 35px;
			}
		}

		p {
			font-size: 12px;
			line-height: 22px;
			color: #999999;
			text-align: right;
		}
	}
	/* Footer Top End */

	.f-nav {
		ul {
			display: block;
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				padding: 0 0 20px;
				margin: 0;
				list-style-type: none;
				color: #fff;
				text-transform: uppercase;
				color: #fff;
				font-size: $font-size-base;
				line-height: $font-size-base;

				&:last-child {
					padding: 0;
				}

				a {
					color: #fff;
					font-size: $font-size-base;
					line-height: $font-size-base;
					text-decoration: none;

					&:hover {
						color: $btn-primary-hover-color;
					}
				}
			}
		}
	}

	.f-address-contact {
		// a {
		// 	text-decoration: none;
		// 	letter-spacing: 5px;
		// 	font-size: 10px;
		// 	line-height: 10px;
		// 	margin: 0 0 17px;
		// 	text-transform: uppercase;
		// 	word-wrap: break-word;
		// }

		// a.telephone {
		// 	color: #cc9933;
		// 	margin: 0 0 15px;
		// 	display: block;

		// 	&:hover {
		// 		color: #fff;
		// 	}
		// }

		// a.mail {
		// 	color: #fff;
		// 	display: block;

		// 	&:hover {
		// 		color: #cc9933;
		// 	}
		// }

		p {
			text-align: left;
			text-decoration: none;
			letter-spacing: 5px;
			font-size: 10px;
			line-height: 10px;
			margin: 0 0 17px;
			text-transform: uppercase;
			word-wrap: break-word;
			color: #cc9933;
			margin: 0 0 15px;
			display: block;
		}

		span {
			text-decoration: none;
			letter-spacing: 5px;
			font-size: 10px;
			line-height: 10px;
			margin: 0 0 17px;
			text-transform: uppercase;
			word-wrap: break-word;
			color: #fff;
			display: block;
		}

		.f-address {
			padding: 13px 0 0;

			ul {
				display: block;
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					margin: 0;
					padding: 0 0 10px;
					list-style-type: none;
					color: #fff;
					font-size: 10px;
					line-height: 10px;
					text-transform: uppercase;

					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.f-social-follower {
		text-align: center;

		.f-follower {
			padding: 48px 0 0;

			ul {
				display: block;
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					display: inline-block;
					padding: 0 25px;
					font-size: 18px;
					line-height: 18px;
					color: #b6b7b7;


					&:first-child {
						padding-left : 0;
					}

					&:last-child {
						padding-right : 0;
					}

					a {
						font-size: 18px;
						line-height: 18px;
						color: #b6b7b7;
						text-decoration: none;

						&:hover {
							color: #cc9933;
						}
					}			
				}
			}
		}
	}

	/* Footer Bottom Start */
	.f-bottom {
		text-align: center;
		color: #999999;

		a {
			color: #999999;
			text-decoration: none;
			font-size: 10px;
			line-height: 10px;

			&:hover {
				color: #666666;
			}
		}

		p {
			font-size: 10px;
			line-height: 10px;
			color: #666666;
			margin: 0 0 12px;
		}

		ul {
			display: block;
			margin: 0;
			padding: 0 0 0 2px;
			list-style-type: none;

			li {
				display: inline-block;
				margin: 0;
				padding: 0 4px 0 0;
				list-style-type: none;
				border-right: 1px solid #666666;
				font-size: 10px;
				line-height: 10px;
				color: #666666;

				&:last-child {
					border-right: none;
					padding-right: 0;
				}



			}
		}
	}
	/* Footer Bottom End */
}
/* Footer Bottom End */