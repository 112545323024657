/*///////////////////////////////////////////////////////////////////////// Responsive CSS Structure /////////////////////////////////////////////////////////////////////////*/


/*---------- Small Mobile , IPhone Start ----------*/

/*=== Screen Size = 240, 320, 360, 480, 568 ===*/ 

@media (min-width: 240px) and (max-width: 568px){
/* Home Page Start */
.newsletter .section-title .discount { left: 50%; top:-70px; transform:translate(-50%,0); -webkit-transform:translate(-50%,0); -moz-transform:translate(-50%,0); -ms-transform:translate(-50%,0); -o-transform:translate(-50%,0); }
.newsletter .mail-box .btn-default { padding: 17px 10px; }
.menu-list .menu-list-left .menu-list-title { padding-bottom: 60px; }
.menu-list .menu-list-right ul.products-list li.products-block .btn-add-to-cart .cart-btn { padding:10px 22px 5px; }
/* Home Page End */
/* Footer */
.footer .f-address-contact span{ line-height: 15px; }
/* Footer */
}

@media (min-width: 569px) and (max-width: 767px){
/* Home Page Start */
.newsletter .section-title .discount { left: -55px; }
/* Home Page End */

/* Blog-Single-Shop Start */
.single-shop-related .item:hover .btn-add-to-cart { top: calc(50% - 50px); }
/* Blog-Single-Shop End */

}

@media (max-width: 767px) {
/* Common Style Start */
.padding-top-150 { padding-top: 80px; }
.specialties-section { padding-bottom:80px; }
.padding-top-120 { padding-top:80px; }
.padding-bottom-220 { padding-bottom:80px; }
.pad-top-bottom { padding:80px 0; }
.padding-bottom-100 { padding-bottom:80px; }
.row.gutter-md { margin-right: -15px; margin-left: -15px; }
.row.gutter-md > [class*="col-"] { padding-right:15px; padding-left:15px; }

.inner-pg { padding:80px 0; }

.row { margin: 0 -15px; }
[class*=col-] { padding: 0 15px; }
.container { width: 100%; }

.multilayers-item { position: absolute; /* width: 100%; */ top: 35%; left: 50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); right: -150px; }
/* Common Style End */

/* Banner Style Start */
.OF-cover { object-fit: cover; font-family: 'object-fit: cover'; height:350px; }
.banner .banner-title h1 { font-size:46px; line-height:50px; }
.banner .banner-title p { font-size:18px; line-height:18px; }
/* Banner Style End */


/* Home Page Start */
.menu-list .menu-list-right ul.products-list li.fancy-pag { height: 230px; }
.restaurant-block { padding: 80px 0; }
.restaurant-block .element-block { display:none; padding-bottom: 20px; }
.restaurant-block .welcome-right { padding: 0; }

.restaurant-menu .menu-left-detail.padding-top-135 { padding-top: 75px; }

.specialties-section:before { display:none; }
.specialties-section .element-block { display: none; }
.specialties-section .specialties-right { padding: 0; }
.specialties-section .multilayers-item.margin-minus-left-120 { margin: 0; }


.menu-list .menu-list-right ul.products-list li.products-block { width: calc(100% - 3px); }

.newsletter .section-title { display: inline-block; }

.restaurant-menu.min-height-720 { min-height: auto; }

.newsletter .mail-box { padding: 0; }
.newsletter .mail-box .form-control { height:51px; }

.reservation-map .reservation-block { min-height:auto; padding:80px 0; }

.menu-list .menu-list-left .menu-list-title { padding-bottom:50px; }
.menu-list .menu-list-left ul li { margin:20px 0; }
.menu-list .menu-list-left { padding-top:65px; }

.slick-prev { left: -15px !important; }
.slick-next { right: -15px !important; }

.padding-bottom-135 { padding-bottom:80px; }

/* Home Page End */
/* button */
.btn-default { padding: 16px 30px; }
/* button */
/* Breadcrumb */
.breadcrumb-list { padding: 30px 0 0; }
.breadcrumb-list .breadcrumb { padding-left: 30px; }
/* Breadcrumb */

/* Contact Page Start */
.contact-content .row { margin: 0 -15px; }
.contact-content .row [class*=col-] { padding: 0 15px; }
/* Contact Page End */

/* Error Page Start */
.section-title h2 { font-size:40px; line-height:42px; }
.error-pg .row { margin: 0 -15px; }
.error-pg .row [class*=col-] { padding: 0 15px; }
.error-pg .error-content [class*=col-] { float: left; width: 100%; display:block; }
.error-pg .error-content .error-right { text-align: center; }
/* Error Page End */

/* Blog-Main-Page Start */
.blog-pages .col-sm-8.col-md-8 { width:100%; }
.blog-pages .col-sm-4.col-md-4 { width:100%; }
.blog-pages { padding:50px 0; }
.blog-detail-header h2 { font-size:26px; line-height:26px; margin-bottom:15px; }
.main-blog-page .blog-item { padding-bottom:10px; margin-bottom:20px; }
.main-blog-page .blog-item h4 { margin-bottom: 15px; }
.sidebar { padding-top:50px; }

.newslatter {padding-left: 15px; padding-right: 15px; padding-bottom: 30px; }
.popular-post .popular-list { padding:0; }
.blog-detail-content .row { margin:0 -15px; }
.blog-detail-content .col-sm-8.col-md-8,
.blog-detail-content .col-sm-4.col-md-4 { padding-left:15px; padding-right:15px; }
/* Blog-Main-Page End */

/* Blog-Single-Page Start */
.blog-detail-content-left { padding:15px; margin:0; }
.blog-related-post .owl-theme .owl-nav { position:relative; top:auto; right:auto; }
.blog-detail-header .blog-detail-meta .posted-date-detail p { font-size:10px; line-height:16px; }
.blog-item-share ul li { margin-right:10px; }
.blog-item-img-wrapper { margin-bottom:15px; }
.blog-item-content p { font-size:14px; line-height:26px; margin-bottom:25px; }
.blog-item-share ul li .shareitem { margin-bottom:10px; }
.post-navigation .nav-links .nav-previous,
.post-navigation .nav-links .nav-next { width:100%; padding:15px; }
.post-navigation .nav-links .nav-next { float:left; text-align:left; border-left:none;  border-top:2px solid #d9d9d9;  }
.post-navigation .nav-links .nav-previous a,
.post-navigation .nav-links .nav-next a { padding:0; }
.author-info .author-detail h5 { margin:10px 0; }
.blog-related-post { margin-bottom:15px; padding-bottom:15px; }
.post-navigation .nav-links .nav-previous a span.nav-subtitle,
.post-navigation .nav-links .nav-next a span.nav-subtitle { font-size: 14px; line-height:14px; margin-bottom: 10px;}
.post-navigation .nav-links .nav-previous a span.nav-detail,
.post-navigation .nav-links .nav-next a span.nav-detail { font-size:18px; line-height:20px; }
.blog-item-share ul li .share-item-title { display:none; }
.blog-detail-header .blog-detail-meta .comments-view ul li { padding-left:0; padding-right:10px; }
.content.blog-detail-content { padding:50px 0; }
form .form-group { margin-bottom: 30px; }
/* Blog-Single-Page End */

/* Blog Single Shop Item Start */
.single-shop-content .slider-pro { padding-left:0 !important; }
.breadcrumb-list .breadcrumb { padding-left:0; }
.breadcrumb-list .breadcrumb li:before { padding:0; }
.inner-pg.single-shop-content { padding:30px 0; }
.single-shop-right { padding:20px 0; }
.single-shop-content .single-shop-title h3 { font-size:24px; line-height:30px; }
.single-shop-content .single-shop-title { padding-bottom:15px; }
.single-shop-content .price { margin-bottom:15px; }
.single-shop-content .single-shop-item-detail p { font-size:14px; line-height:22px; margin-bottom:20px; }
.single-shop-content .single-cart input[type="text"] { width:100%; margin-bottom:10px; }
.feedback { padding:0 0 20px 0; }
.feedback .tab-content { padding:15px; }
.feedback h5 { margin-bottom:20px; }
.feedback .tab-content textarea { height:100px; }
.feedback  form .form-group { margin-bottom:15px; }
.single-shop-related { padding:40px 0; }

.single-shop-content .single-shop-left { padding:0; }
.single-shop-content .single-shop-item-nav { position:relative; left:auto; width:auto; top:auto; margin-top:15px; }

/* Blog Single Shop Item End */

/* Contact Start */
.contact-content h5 { margin:0 0 30px 0; }
/* Contact End */

/* Menu-List-3-Row-Page Start */
.menu-list-image-block img.OF-cover { height:auto; }
.menu-list ul li .price-item-main .list-item-title { font-size:14px; line-height:14px; margin-bottom:0; }
.menu-list-image-block { margin-top:0; margin-bottom:20px; }
.menu-list ul li { margin:15px 0; }
.menu-list ul li .price-item-desc p { margin-bottom:0; }
.menu-list-full { padding-left:15px; padding-right:15px; padding-top:50px; padding-bottom:50px; }
/* Menu-List-3-Row-Page End */

/* Menu-List-With-Container Start */
.menu-list-container { padding:50px 0; }
/* Menu-List-With-Container End */

/* Reservation Start */
.reservation-form { margin:0; padding:40px 0; }
.reservation-section .form-group { margin:0 0 20px 0; padding:0 20px; }
.reservation-form h5 { margin:0 0 20px 0; }
.reservation-section .contact-detail .form-group { margin:0 0 20px 0; }
/* Reservation End */

/* Reservation Oblique Start */
.main-block-oblique {  -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; -webkit-box-orient: vertical; -webkit-flex-wrap: wrap; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.oblique .skew-block .skew-block-repeat { margin:0 0 10px 0; }

/* Reservation Oblique End */

/* Cart Page Start */
.cart-pg .cart-collaterals .cart-totals .proceed-to-checkout a.btn-default { font-size:12px; line-height:12px; }
.cart-pg .cart-collaterals .cart-totals { padding:20px; }
.cart-pg .cart-header h2 { font-size:24px; }
/* Cart Page End */

/* Full Shop Width Start */
.full-shop-width .menu-list .menu-list-right ul.products-list li.products-block { width:100%; }
/* Full Shop Width End */

/* Newsletter Start */
.newsletter { padding:116px 0 80px 0; }
/* Newsletter End */

/* Footer Start */
.footer .btn-default { letter-spacing:2px; }
.footer .f-top .row { margin: 0 -15px; }
.footer .f-top .row [class*=col-] { padding: 0 15px; }
.f-top { text-align:center; }
.footer .f-social-follower .f-follower { padding-bottom: 35px; }
.f-top .f-nav { padding-bottom: 35px; }
.f-top .head-chef p { text-align: center; }

/* Footer End */

}

@media (min-width: 768px) and (max-width: 991px){
/* Common Style Start */
.restaurant-block .padding-left-250 { padding-left: 180px; }
.padding-bottom-100 { padding-bottom:80px; }
.padding-top-120 { padding-top:80px; }
.padding-bottom-180 { padding-bottom:80px; }
.padding-top-150 { padding-top:80px; }
.main-blog-page .load-more-btn { padding-bottom:30px; }

.row { margin: 0 -15px; }
[class*=col-] { padding: 0 15px; }
.container { width: 100%; }
.multilayers-item { position: absolute; width:auto; top: 35%; left: 50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); -moz-transform: translate(-50%,-50%); -o-transform: translate(-50%,-50%); right: -150px; }
/* Common Style End */

/* Banner Style Start */
.OF-cover { object-fit: cover; font-family: 'object-fit: cover'; height:350px; }
.banner .banner-title h1 { font-size:70px; line-height:62px; }
/* Banner Style End */


/* Home Page Start */

.menu-list .menu-list-left .menu-list-title { padding-bottom:30px; }
.menu-list .menu-list-left ul li { margin-bottom:15px; }
.menu-list ul li {  margin-top: 15px; margin-bottom: 15px; }
.menu-list .menu-list-right ul.products-list li.fancy-pag { height: 230px; }

.restaurant-block .element-block { padding-bottom: 20px; } 
.restaurant-block .welcome-right { padding: 0; }
.restaurant-menu .menu-left-detail.padding-top-135 { padding-top: 75px; }

.specialties-section { padding-bottom: 120px; }
.specialties-section .specialties-right { padding: 170px 0 0; }
.specialties-section .multilayers-item.margin-minus-left-120 { margin: 0; }

.menu-list .menu-list-right ul.products-list li.products-block { width: calc(50% - 3px); }

.newsletter .section-title { display: inline-block; }

.restaurant-menu.min-height-720 { min-height: auto; }

.newsletter .section-title .discount { left: -60px; }

.specialties-section .specialties-right { padding:20px 0 0 0; }

.slick-prev { left: -15px !important; }
.slick-next { right: -15px !important; }
/* Home Page End */

/* Blog-Page Start */
.blog-pages .col-sm-8.col-md-8 { width:100%; }
.blog-pages .col-sm-4.col-md-4 { width:100%; }

.content.blog-detail-content { padding:20px 0; }
.blog-detail-content .row { margin:0 -15px; }
.blog-detail-content .col-sm-8.col-md-8,
.blog-detail-content .col-sm-4.col-md-4 { padding-left:15px; padding-right:15px; }
/* Blog-Page End */

/* Blog-Single-Page Start */
.blog-detail-content-left { padding:15px; }
.inner-pg.single-shop-content { padding:50px 0; }
/* Blog-Single-Page End */

/* Blog Single Shop Item Start */
.single-shop-content .slider-pro { padding-left:100px !important; }
.single-shop-content .single-shop-title h3 { font-size:22px; line-height:28px; }
.single-shop-content .single-shop-title { padding-bottom:15px; }
.single-shop-content .price { margin-bottom:15px; }
.single-shop-content .single-shop-item-detail p { line-height:24px; margin-bottom: 20px; }
.single-shop-content .single-cart .btn-default { padding:18px 10px; }
.feedback .tab-content { padding:20px; }
.feedback h5 { margin-bottom:20px; }
.feedback .tab-content textarea { height:100px; }
.feedback form .form-group { margin:0 0 20px; }
.single-shop-related .item:hover .btn-add-to-cart { top: calc(50% - 55px); }
.single-shop-related { padding:40px 0; }
/* Blog Single Shop Item End */


/* button */
.btn-default { padding: 16px 30px; }
/* button */
/* Breadcrumb Start */
.breadcrumb-list .breadcrumb { padding-left: 50px; }
/* Breadcrumb End */
/* Contact Page Start */
.contact-content .row { margin: 0 -15px; }
.contact-content .row [class*=col-] { padding: 0 15px; }
/* Contact Page End */
/* Error Page Start */
.error-pg .row { margin: 0 -15px; }
.error-pg .row [class*=col-] { padding: 0 15px; }
.error-pg .error-content [class*=col-] { float: left; width: 100%; display:block; }
.error-pg .error-content .error-right { text-align: center; padding: 50px 0 0; }
/* Error Page End */

/* Reservation-Oblique Start */
.oblique .skew-block .oblique-inner { -webkit-transform: skewX(0); transform: skewX(0); }
.oblique .skew-block .oblique-inner .image-wrapper { -webkit-transform: skewX(0); transform: skewX(0); }
/* Reservation-Oblique End */

/* Menu-List-3-Row-Page Start */
.menu-list-image-block img.OF-cover { height:auto; }
.menu-list-image-block { margin-top:0; }
/* Menu-List-3-Row-Page End */

/* Reservation Start */
.reservation-form { margin:0; padding:80px 0; }
/* Reservation End */

/* Full Shop Width Start */
.full-shop-width .menu-list .menu-list-right ul.products-list li.products-block { width: calc(50% - 3px); }
/* Full Shop Width End */

/* Footer Start */
.footer .f-top .row { margin: 0 -15px; }
.footer .f-top .row [class*=col-] { padding: 0 15px; }
.f-top { text-align:center; }
.footer .f-nav ul li { display:inline-block; padding:0 20px; }
.footer .f-social-follower .f-follower { padding-bottom: 35px; }
.f-top .f-nav { text-align: center; padding-bottom: 35px; }
.f-top .head-chef p { text-align: center; }
/* Footer End */
}

@media (max-width: 991px) {
.header .header-container { max-width: 100%; padding-left: 15px; padding-right: 15px; }
/* Navigation */
.navbar-toggle { display: block; }

.header .header-home { padding:35px 0; }
.header .header-home.sticky-header { padding: 30px 0; }
/* Navigation */

/* Home Page Start */
.restaurant-menu .menu-right-img { position: relative; top: auto; right: auto; width: 100%; height: 400px; object-fit:cover; font-family: 'object-fit:cover'; background-position: center center; }
.restaurant-menu .menu-inner-left.padding-right-90 { padding-right: 0; padding-bottom: 70px; }
/* Home Page End */

/* Footer Start */
.footer .f-address-contact p { text-align: center; }
/* Footer End */
}

@media (min-width: 992px) {
.navbar ul li.open ul.dropdown-menu { display: none; }
.navbar .navbar-nav > li:hover ul.dropdown-menu { display: block; }

/* Sticky Header Start */

	.header-home.sticky-header {
		background-color:#000;
	    -webkit-transition: background-color .2s ease-out;
    	transition: background-color .2s ease-out;
		position: fixed;
	    -webkit-animation: headerSlide .2s ease-out;
	    animation: headerSlide .2s ease-out;
	    top: 0;
		left: 0;
		right: 0;
		background-color:#000;
		z-index:9999;
		padding: 0;

		.navbar-nav > li > a {
			padding: 30px;
		}

		.navbar ul li:after {
			bottom: 0;
		}
	}
	
	.dn-stickyRelativeHelper {
		display:none;
	}
	
	.header-home.header-relative.sticky-header + .dn-stickyRelativeHelper
	{
		display:block;
	
	}
/* Sticky Header End */

// Header Animation
@-webkit-keyframes headerSlide { 0% { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
100% { -webkit-transform: translateY(0); transform: translateY(0); } }
@keyframes headerSlide { 0% { -webkit-transform: translateY(-100%); transform: translateY(-100%); }
100% { -webkit-transform: translateY(0); transform: translateY(0); } }
}

@media (min-width: 992px) and (max-width: 1199px){

.padding-bottom-180 { padding-bottom:80px; }
.padding-bottom-220 { padding-bottom:80px; }
/* Header */
.header .header-container { max-width: 100%; padding-left: 15px; padding-right: 15px; }
.header-left { -webkit-flex-basis: 25%; -ms-flex-preferred-size: 25%; flex-basis: 25%; }
.header .navbar-nav > li > a { padding: 35px 12px 28px; }
.header .header-home.sticky-header .navbar-nav > li > a { padding: 30px 12px; }
.header .header-cart .headercartbtn .cart-btn-item,
.header .header-cart .headercartbtn .cart-btn-total { display: none; }
.newsletter .section-title .discount { left: 65px; }
/* Header */
/* Home Page Start */
.menu-list .menu-list-right ul.products-list li.fancy-pag { height: 230px; }
.menu-list .menu-list-left { padding-bottom: 100px; }
.menu-list .menu-list-left .menu-list-title { padding-bottom:60px; }
.menu-list .menu-list-left ul li { margin-bottom: 15px; }
.menu-list ul li { margin-top: 15px; }
.menu-list .menu-list-right ul.products-list li.products-block:hover .btn-add-to-cart { top: calc(50% - 50px); }
.menu-list .menu-list-right ul.products-list li.products-block .btn-add-to-cart .cart-btn { padding:12px; }
/* Home Page End */

/* Blog-Single-Page Start */
.banner .banner-title h1 { font-size:60px; line-height:60px; }

.blog-detail-content-left { margin-top:0; }
.blog-detail-header h2 { font-size:26px; line-height:26px; margin-bottom:10px; }
.blog-item-img-wrapper { margin-bottom:15px; }
.blog-item-content p { font-size:14px; line-height:28px; }
.inner-pg.single-shop-content { padding:50px 0; }
.single-shop-content .single-shop-title h3 { font-size:30px; line-height:32px; }
.single-shop-content .single-shop-title { padding-bottom:20px; }
.single-shop-content .price { margin-bottom:20px; }
.single-shop-content .single-shop-item-detail p { margin-bottom:20px; }
.feedback .tab-content textarea { height:100px; }
.feedback .tab-content { padding:25px; }

.post-navigation .nav-links .nav-previous a span.nav-detail,
.post-navigation .nav-links .nav-next a span.nav-detail { font-size: 18px; line-height: 20px; }
.post-navigation .nav-links .nav-previous a, .post-navigation .nav-links .nav-next a { padding: 20px 0 20px; }
.blog-related-post .related-blog .item img { margin-bottom:15px; }
.blog-related-post .related-blog p { line-height:20px; }
.blog-related-post { margin-bottom:20px; padding-bottom:20px; }
.comments-area { padding:20px 0; }

.newslatter { padding:15px; }
.popular-post .popular-list { padding:0; }
.newslatter h4 { margin-bottom:20px; font-size:20px; line-height:26px; }
.newslatter form .form-group { margin-bottom:20px; }

/* Blog-Single-Page End */
/* menu-list-3-row-page Start */
.menu-list-image-block { margin-top:0; }
.menu-list ul li { margin-top:10px; margin-bottom:10px; }
.menu-list ul li .price-item-desc p { margin:0; }
.menu-list ul li .price-item-main .list-item-title { font-size: 12px; line-height: 14px; margin-bottom:0; }

/* menu-list-3-row-page End */

/* Contact Page Start */
.contact-content .row { margin: 0 -23px; }
.contact-content .row [class*=col-] { padding: 0 23px; }
/* Contact Page End */

/* Error Page Start */
.error-pg .section-title h2 { font-size: 40px; line-height:40px; }
.section-title { padding-bottom:5px; }
/* Error Page End */

/* button */
.btn-default { padding: 16px 30px; }
/* button */

/* Footer Start */

/* Newsletter Section Start */
.newsletter .mail-box { padding:0; }
/* Newsletter Section End */

/* Restaurant Menu Start */
.menu-list-image-block img.OF-cover { object-fit: cover; font-family: 'object-fit: cover'; }
/* Restaurant Menu End */

/* Reservation Start */
.reservation-form { padding:50px 0; }
.padding-left-125 { padding-left:25px; }
/* Reservation End */

/* Reservation-Oblique Start */
.oblique .skew-block .oblique-inner { -webkit-transform: skewX(0); transform: skewX(0); }
.oblique .skew-block .oblique-inner .image-wrapper { -webkit-transform: skewX(0); transform: skewX(0); }
/* Reservation-Oblique End */

/* Full Shop Width Start */
.full-shop-width .menu-list .menu-list-right ul.products-list li.products-block { width: calc(33.3333333333% - 3px); }
/* Full Shop Width End */

/* Footer Start */
.footer .f-top .row { margin: 0 -20px; }
.footer .f-top .row [class*=col-] { padding: 0 20px; }
/* Footer End */
}

@media (min-width: 1200px) and (max-width: 1820px){
/* Header */
.header .header-container { max-width: 100%; padding-left: 15px; padding-right: 15px; }
/* Header */
}


@media (min-width: 1200px) and (max-width: 1299px){
.container { width: 1170px; }
/* Home Page Start */
.menu-list .menu-list-right ul.products-list li.fancy-pag { height: 280px; }
.newsletter .section-title .discount { left: 123px; }
/* Home Page End */
/* Contact Page Start */
.contact-content .row { margin: 0 -26px; }
.contact-content .row [class*=col-] { padding: 0 26px; }
/* Contact Page End */

/* menu-list-3-row-page Start */
.menu-list-image-block { margin-top:0; }
.menu-list ul li { margin-top:10px; margin-bottom:10px; }
.menu-list ul li .price-item-desc p { margin:0; }
/* menu-list-3-row-page End */

/* Footer Start */
.footer .f-top .row { margin: 0 -26px; }
.footer .f-top .row [class*=col-] { padding: 0 26px; }
/* Footer End */
}

@media (min-width: 1300px) and (max-width: 1399px){
.container { width: 1270px; }
/* Home Page Start */
.menu-list .menu-list-right ul.products-list li.fancy-pag { height: 305px; }
.newsletter .section-title .discount { left: 150px; }
/* Home Page End */
/* Contact Page Start */
.contact-content .row { margin: 0 -26px; }
.contact-content [class*=col-] { padding: 0 26px; }
/* Contact Page End */

/* menu-list-3-row-page Start */
.menu-list-image-block { margin-top:0; }
.menu-list ul li { margin-top:10px; margin-bottom:10px; }
.menu-list ul li .price-item-desc p { margin:0; }
/* menu-list-3-row-page End */

/* Footer Start */
.footer .f-top .row { margin: 0 -26px; }
.footer .f-top .row [class*=col-] { padding: 0 26px; }
/* Footer End */
}

@media (min-width: 1400px) and (max-width: 1450px){
.container { width: 1370px; }
/* Home Page Start */
.menu-list .menu-list-right ul.products-list li.fancy-pag { height: 330px; }
/* Home Page End */
/* Contact Page Start */
.contact-content .row { margin: 0 -26px; }
.contact-content .row [class*=col-] { padding: 0 26px; }
/* Contact Page End */
/* Footer Start */
.footer .f-top .row { margin: 0 -26px; }
.footer .f-top .row [class*=col-] { padding: 0 26px; }
/* Footer End */
}

@media (min-width: 1200px) and (max-width: 1399px){
/* Header */
.header-left { -webkit-flex-basis: 25%; -ms-flex-preferred-size: 25%; flex-basis: 25%; }
.header .header-home.sticky-header .navbar-nav > li > a { padding: 30px 21px; }
.header .navbar-nav > li > a { padding: 35px 21px 28px; }
/* Header */
/* button */
.btn-default { padding: 16px 30px; }
/* button */
}

@media (min-width: 1400px) and (max-width: 1605px){
/* Header */
.header-left { -webkit-flex-basis: 25%; -ms-flex-preferred-size: 25%; flex-basis: 25%; }
.header .navbar-nav > li > a { padding: 35px 30px 28px; }
/* Header */
}




/*---------- Large Desktop , Large Screen End ----------*/
